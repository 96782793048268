import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import AudioPlayer from "../components/AudioPlayer";
import SectionHeading from "../components/SectionHeading";
import BottomNav from "../components/BottomNav";

const SectionStyles = styled.div`
  margin: 0;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default function quote({ data: { intro } }) {
  return (
    <SectionStyles>
      <SectionHeading heading="शुरु में शब्द" />
      <AudioPlayer url={intro.audio.asset.url} />
      <p className="quote nobottom">
        शुरु में शब्द था और शब्द परमेश्वर के साथ था और शब्द ही परमेश्वर था।
        उन्हीं के द्वारा परमेश्वर ने सब कुछ बनाया, उनके बिना कुछ नहीं बना। उनमें
        जीवन है और जीवन लोगों के लिये ज्योति है। वह ज्योति अंधेरे में चमकती रहती
        है, और अंधेरा उसे नहीं रोक सकता।
      </p>
      <p className="ref quote">(योहन 1:1-5)</p>
      <BottomNav current={0} />
    </SectionStyles>
  );
}

export const query = graphql`
  query {
    intro: sanityIntro(name: { eq: "quote" }) {
      id
      audio {
        asset {
          url
        }
      }
    }
  }
`;
